import { useState, useEffect } from 'react'

import {
  Badge,
  Box,
  IconButton,
  Theme,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { FilterList as FilterListIcon } from '@material-ui/icons'

import StepperForm from '~/components/StepperForm'
import { Finder, PageHeader } from '~/components'

import EnvioESocial from './EnvioESocial'
import AcompanhamentoESocial from './AcompanhamentoESocial'
import DrawerFilter from './DrawerFilter'

import { useParams } from 'react-router-dom'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'

export interface DataContextProps {
  query: string
  indApuracao: IndApuracaoEnum
  eventosFilter: ESocialEventoEnum[]
  grupoId: string | null
  changeEnvioConsulta: React.Dispatch<React.SetStateAction<number>> | null
  setIndApuracao: React.Dispatch<React.SetStateAction<IndApuracaoEnum>>
}

export interface UseStylesProps {
  isSmallWidth: boolean
}

export const HEIGHT_STEP_ROOT = 48
export const WIDTH_STEP_ROOT_SMALL = 120
export const WIDTH_STEP_ROOT = 225

const useStyles = makeStyles<Theme, UseStylesProps>((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  rootContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  stepsBase: {
    width: (props) => (props.isSmallWidth ? WIDTH_STEP_ROOT_SMALL : WIDTH_STEP_ROOT),
    height: HEIGHT_STEP_ROOT,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    flex: 1,
  },
}))

export default function TransmissaoESocial() {
  const [query, setQuery] = useState('')
  const [grupoId, setGrupoId] = useState<string | null>(null)
  const [eventosFilter, setEventosFilter] = useState<ESocialEventoEnum[]>([])
  const [indApuracao, setIndApuracao] = useState<IndApuracaoEnum>(IndApuracaoEnum.Mensal_1)

  const { mes } = useAmbiente()

  const { step } = useParams<{ step: string }>()

  const dataContext: DataContextProps = {
    query,
    eventosFilter,
    indApuracao,
    grupoId,
    changeEnvioConsulta: null,
    setIndApuracao,
  }

  let numberFilterActives = 0

  const isDezembro = mes === '12'

  if (eventosFilter.length > 0) {
    numberFilterActives += 1
  }
  if (isDezembro) {
    numberFilterActives += 1
  }
  if (grupoId) {
    numberFilterActives += 1
  }

  const theme = useTheme()
  const isSmallWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const {
    close: closeDrawerFilter,
    data: dataDrawerFilter,
    isOpen: isOpenDrawerFilter,
    open: openDrawerFilter,
  } = useDialog<DataContextProps>(dataContext)

  useEffect(() => {
    setIndApuracao(IndApuracaoEnum.Mensal_1)
  }, [mes])

  const useStylesProps: UseStylesProps = {
    isSmallWidth,
  }

  const classes = useStyles(useStylesProps)

  function handleSubmitFilter(newFilters: DataContextProps) {
    setEventosFilter(newFilters.eventosFilter)
    setIndApuracao(newFilters.indApuracao)
    setGrupoId(newFilters.grupoId)
  }

  const title =
    'Transmissão eSocial ' +
    (isDezembro
      ? indApuracao === IndApuracaoEnum.Anual_2
        ? ' - Décimo Terceiro'
        : ' - Mensal'
      : '')

  return (
    <StepperForm.Provider
      disabledAlert
      steps={[
        {
          label: 'Envio',
          icon: 'description',
          children: <EnvioESocial />,
        },
        {
          label: 'Consulta',
          icon: 'insert_chart',
          children: <AcompanhamentoESocial />,
        },
      ]}
      dataControlled={dataContext}
      initialStep={parseInt(step)}
    >
      <Box className={classes.root}>
        <PageHeader title={title}>
          <Box
            display="flex"
            gridGap={theme.spacing(1)}
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              size="small"
              onClick={() => openDrawerFilter(dataContext)}
              title="Abrir Transmissão eSocial - Filtros"
            >
              <Badge badgeContent={numberFilterActives}>
                <FilterListIcon color={numberFilterActives > 0 ? 'primary' : 'action'} />
              </Badge>
            </IconButton>

            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          </Box>
        </PageHeader>

        <Box className={classes.rootContent}>
          <Box className={classes.stepsBase}>
            <StepperForm.Steps orientation="horizontal" />
          </Box>

          <Box className={classes.content}>
            <StepperForm.Content
              style={{
                height: '100%',
              }}
            />
          </Box>
        </Box>
      </Box>

      <DrawerFilter
        isOpen={isOpenDrawerFilter}
        onClose={closeDrawerFilter}
        data={dataDrawerFilter}
        onSubmit={handleSubmitFilter}
      />
    </StepperForm.Provider>
  )
}
