import { Grid } from '@material-ui/core'

import StepperForm from '~/components/StepperForm'

import FormAlteracaoContratual from './FormAlteracaoContratual'
import FormVinculo from './FormVinculo'
import TableSalarioContratual from './TableSalarioContratual'
import TableAmbienteTrabalho from './TableAmbienteTrabalho'
import TableHorarioTrabalho from './TableHorarioTrabalho'
import TableTreinamentos from './TableTreinamentos'

import { useSalvarEvento2206 } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/useSalvarEvento2206'
import { useCriarEvento2206 } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/useCriarEvento2206'

import {
  VinculoAlteracaoContratoCadastro,
  VinculoAlteracaoContratoCadastroPartial,
} from '~/hooks/queries/VinculoAlteracaoContratoCadastro/VinculoAlteracaoContratoCadastro'
import { VinculoAlteracaoContrato2206DTO } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/VinculoAlteracaoContrato2206DTO'
import { Vinculo, VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import {
  VinculoSalarioBase,
  VinculoSalarioBasePartial,
} from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'
import { VinculoLocal, VinculoLocalPartial } from '~/hooks/queries/VinculoLocal/VinculoLocal'
import {
  VinculoHorario,
  VinculoHorarioPartial,
} from '~/hooks/queries/VinculoHorario/VinculoHorario'
import {
  VinculoTreinamento,
  VinculoTreinamentoPartial,
} from '~/hooks/queries/VinculoTreinamento/VinculoTreinamento'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

export interface MainDataForm {
  dataAlteracaoContrato: VinculoAlteracaoContratoCadastroPartial
  dataVinculo: VinculoPartial
  dataSalarioBaseList: VinculoSalarioBasePartial[]
  dataLocalList: VinculoLocalPartial[]
  dataHorarioList: VinculoHorarioPartial[]
  dataTreinamentoList: VinculoTreinamentoPartial[]
}

interface MainDataFormSubmit {
  dataAlteracaoContrato: VinculoAlteracaoContratoCadastro
  dataVinculo: Vinculo
  dataSalarioBaseList: VinculoSalarioBase[]
  dataLocalList: VinculoLocal[]
  dataHorarioList: VinculoHorario[]
  dataTreinamentoList: VinculoTreinamento[]
}

interface FlowCreateProps {
  data: VinculoAlteracaoContrato2206DTO
  onCancel: () => void
  isEdit: boolean
  isRetificar?: boolean
}

const stepsFuncionario = [
  {
    label: 'Alteração Contratual',
    children: <FormAlteracaoContratual />,
    icon: 'assignment_ind',
  },
  {
    label: 'Vínculo',
    children: <FormVinculo />,
    icon: 'person',
  },
  {
    label: 'Salário Contratual',
    children: <TableSalarioContratual />,
    icon: 'attach_money',
  },
  {
    label: 'Ambiente de Trabalho',
    children: <TableAmbienteTrabalho />,
    icon: 'work',
  },
  {
    label: 'Horário de Trabalho',
    children: <TableHorarioTrabalho />,
    icon: 'query_builder',
  },
  {
    label: 'Treinamentos',
    children: <TableTreinamentos />,
    icon: 'assessment',
  },
]

const stepsEmpregador = [
  {
    label: 'Alteração Contratual',
    children: <FormAlteracaoContratual />,
    icon: 'assignment_ind',
  },
  {
    label: 'Vínculo',
    children: <FormVinculo />,
    icon: 'person',
  },
  {
    label: 'PRÓ-LABORE',
    children: <TableSalarioContratual />,
    icon: 'attach_money',
  },
  {
    label: 'Ambiente de Trabalho',
    children: <TableAmbienteTrabalho />,
    icon: 'work',
  },
]
const stepsEstagiario = [
  {
    label: 'Alteração Contratual',
    children: <FormAlteracaoContratual />,
    icon: 'assignment_ind',
  },
  {
    label: 'Vínculo',
    children: <FormVinculo />,
    icon: 'person',
  },
  {
    label: 'Bolsa-Estágio',
    children: <TableSalarioContratual />,
    icon: 'attach_money',
  },
  {
    label: 'Ambiente de Trabalho',
    children: <TableAmbienteTrabalho />,
    icon: 'work',
  },
  {
    label: 'Horário de Trabalho',
    children: <TableHorarioTrabalho />,
    icon: 'query_builder',
  },
]
const stepsAutonomo = [
  {
    label: 'Alteração Contratual',
    children: <FormAlteracaoContratual />,
    icon: 'assignment_ind',
  },
  {
    label: 'Vínculo',
    children: <FormVinculo />,
    icon: 'person',
  },
  {
    label: 'Serviços',
    children: <TableSalarioContratual />,
    icon: 'attach_money',
  },
  {
    label: 'Ambiente de Trabalho',
    children: <TableAmbienteTrabalho />,
    icon: 'work',
  },
]
const stepsCooperado = [
  {
    label: 'Alteração Contratual',
    children: <FormAlteracaoContratual />,
    icon: 'assignment_ind',
  },
  {
    label: 'Vínculo',
    children: <FormVinculo />,
    icon: 'person',
  },
  {
    label: 'Ambiente de Trabalho',
    children: <TableAmbienteTrabalho />,
    icon: 'work',
  },
]

export default function FlowCreate(props: FlowCreateProps) {
  const { data, onCancel, isEdit, isRetificar } = props

  const { mutateAsync: salvarEvento2206, isLoading: isLoadingSalvar } = useSalvarEvento2206()
  const { mutateAsync: criarEvento2206, isLoading: isLoadingCriar } = useCriarEvento2206()

  const isLoading = isLoadingSalvar || isLoadingCriar

  const mainData: MainDataForm = {
    dataAlteracaoContrato: data.vinculoAlteracaoContrato,
    dataVinculo: data.vinculo,
    dataSalarioBaseList: data.vinculoSalarioBaseList,
    dataLocalList: data.vinculoLocalList,
    dataHorarioList: data.vinculoHorarioList,
    dataTreinamentoList: data.vinculoTreinamentoList,
  }

  let stepsCurrent = stepsFuncionario

  switch (data.vinculo.tipoRegistro) {
    case VinculoTipoEnum.Funcionario_1:
      stepsCurrent = stepsFuncionario
      break
    case VinculoTipoEnum.Empregador_2:
      stepsCurrent = stepsEmpregador
      break
    case VinculoTipoEnum.Estagiario_3:
      stepsCurrent = stepsEstagiario
      break
    case VinculoTipoEnum.Autonomo_4:
      stepsCurrent = stepsAutonomo
      break
    case VinculoTipoEnum.Cooperado_5:
      stepsCurrent = stepsCooperado
      break
    default:
      stepsCurrent = stepsFuncionario
      break
  }

  async function handleSubmit(data: MainDataFormSubmit) {
    // eslint-disable-next-line
    const newLocaisTrabalho = data.dataLocalList.map(({ cargo, setor, departamento, ...rest }) => ({
      ...rest,
    }))
    const newHorarios = data.dataHorarioList.map((horario) => ({
      ...horario,
      // eslint-disable-next-line
      vinculoHorarioItens: horario.vinculoHorarioItens?.map(({ horarioTrabalho, ...rest }) => ({
        ...rest,
      })),
    }))

    const dataSubmit = {
      vinculoAlteracaoContrato: data.dataAlteracaoContrato,
      vinculo: data.dataVinculo,
      vinculoSalarioBaseList: data.dataSalarioBaseList,
      vinculoLocalList: newLocaisTrabalho,
      vinculoHorarioList: newHorarios,
      vinculoTreinamentoList: data.dataTreinamentoList,
    }

    if (isEdit) {
      await salvarEvento2206({
        data: dataSubmit,
        isRetificar: isRetificar || false,
      })
    } else {
      await criarEvento2206(dataSubmit)
    }
    onCancel()
  }

  return (
    <StepperForm.Provider<MainDataForm>
      onSubmit={(dt) => handleSubmit(dt as MainDataFormSubmit)}
      onCancel={onCancel}
      data={mainData}
      isLoading={isLoading}
      disabled={isEdit && (isRetificar === undefined ? false : !isRetificar)}
      steps={stepsCurrent}
    >
      <Grid container spacing={0} style={{ marginBottom: '3rem' }}>
        <Grid item xs={1} md={2}>
          <StepperForm.Steps />
        </Grid>
        <Grid item xs={11} md={10}>
          <StepperForm.Content />
        </Grid>
      </Grid>
    </StepperForm.Provider>
  )
}
