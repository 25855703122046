import useCredentials from './useCredentials'

const routesOnlyDataC = [
  '/configuracao-relatorio',
  '/auditoria-empregador',
  '/limpar-gerar-ambiente',
  '/terceiros',
  '/transmissao-esocial-old',
  '/modelo-integracao',
  '/configuracao-lancamento',
  '/gerar-arquivo-importacao',
]

export default function useOnlyDataC() {
  const { userLogged } = useCredentials()
  const isUserDataC = userLogged?.email.endsWith('@datac.com.br') ? true : false
  return { isUserDataC, routesOnlyDataC }
}
