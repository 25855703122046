import { Box, Grid, useTheme } from '@material-ui/core'

import { PageHeader } from '~/components'

import AdmissoesChart from './AdmissoesChart'
import RescisoesChart from './RescisoesChart'

import TransmissaoChartConsulta from './TransmissaoChartConsulta'
import TransmissaoChartEnvio from './TransmissaoChartEnvio'
import RecibosChart from './RecibosChart'
import CompetenciaChart from './CompetenciaChart'
import VencimentosChart from './VencimentosChart'

export const MAX_WIDTH_CHART = 375
export const MAX_HEIGHT_CHART = 375

export default function Painel() {
  const theme = useTheme()
  return (
    <Box p={2} display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <PageHeader title="Painel" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <AdmissoesChart />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <RescisoesChart />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <TransmissaoChartEnvio />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <TransmissaoChartConsulta />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <RecibosChart />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <VencimentosChart />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <CompetenciaChart />
        </Grid>
      </Grid>
    </Box>
  )
}
