import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPost } from '~/utils/notification'

import { useObterTodosSalarioInvalidate } from './useObterTodosSalario'

import { SalarioDTO } from './dtos/SalarioDTO'
import { Salario } from './Salario'

export function useCreateSalario() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosSalarioInvalidate()

  async function handleRequest(data: SalarioDTO) {
    const response = await api.post<{ data: Salario }>('/Salario', data)
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
