import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPost } from '~/utils/notification'

import { useObterTodosSalarioItemInvalidate } from './useObterTodosSalarioItem'

import { SalarioItemDTO } from './dtos/SalarioItemDTO'

export function useCreateSalarioItem() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosSalarioItemInvalidate()

  async function handleRequest(data: SalarioItemDTO) {
    await api.post('/SalarioItem', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
