import { MUIAutoComplete } from '~/components/AutoComplete'

import { useObterTodosSalario } from '~/hooks/queries/Salario/useObterTodosSalario'

import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'
import { Salario } from '~/hooks/queries/Salario/Salario'

interface AutoCompleteSalarioProps extends MUIAutoCompleteProps {
  value: string
  onChange: (value: Salario | null) => void
}

export default function AutoCompleteSalario(props: AutoCompleteSalarioProps) {
  const { value, onChange, ...rest } = props

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
    refetch,
  } = useObterTodosSalario()
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: Salario) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Salário"
      value={value}
      onChange={(_, obj: Salario) => onChange(obj)}
      optionId="id"
      {...rest}
    />
  )
}
