import { Box, Icon, Typography, useTheme } from '@material-ui/core'
import { formatCurrency } from '~/utils/utils'

interface CardFooterProps {
  color: string
  cardIcon: string
  percent: number
}

export default function CardFooter({ color, cardIcon, percent }: CardFooterProps) {
  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center" gridGap={theme.spacing(0.5)}>
      <Box color={color} display="flex" alignItems="center">
        <Icon>{cardIcon}</Icon>
      </Box>
      <Box component={Typography} color={color}>
        {formatCurrency(Math.abs(percent), true)}%
      </Box>
      <Typography>em comparação ao último mês</Typography>
    </Box>
  )
}
