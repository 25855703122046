import { useState } from 'react'

import * as yup from 'yup'

import { Divider, Grid } from '@material-ui/core'

import { ActionDialog, Stack, TextField } from '~/components'

import SalarioItens from './SalarioItens'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCreateSalario } from '~/hooks/queries/Salario/useCreateSalario'
import { useUpdateSalario } from '~/hooks/queries/Salario/useUpdateSalario'

import { Salario } from '~/hooks/queries/Salario/Salario'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: Salario | null
}

export default function Form({ isOpen, onClose, data: _data }: FormProps) {
  const [data, setData] = useState<Salario>(
    _data || {
      id: '',
      codigo: 0,
      nome: '',
    },
  )

  const isUpdate = data.id ? true : false

  const { mutateAsync: mutateAsyncCreate, isLoading: isCreating } = useCreateSalario()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } = useUpdateSalario()

  const isLoading = isCreating || isUpdating

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    if (isUpdate) {
      await mutateAsyncUpdate({
        data: {
          nome: data.nome,
        },
        id: data.id,
      })
      onClose()
    } else {
      const novaData = await mutateAsyncCreate({
        nome: data.nome,
      })
      setData(novaData)
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Salário"
      isOpen={isOpen}
      onClose={onClose}
      okLabel={isUpdate ? 'Salvar' : 'Continuar'}
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: isUpdate ? 'md' : 'sm', fullWidth: true }}
    >
      <Stack>
        <Grid container spacing={2}>
          {isUpdate && (
            <Grid item xs={12} sm={6} md={2}>
              <TextField label="Código" value={data.codigo} disabled />
            </Grid>
          )}

          <Grid item xs={12} sm={isUpdate ? 6 : 12} md={isUpdate ? 10 : 12}>
            <TextField
              label="Nome"
              value={data.nome}
              onChange={(e) => {
                const nome = e.target.value
                setData((prev) => ({
                  ...prev,
                  nome,
                }))
              }}
              required
              validationErrors={validationErrors}
              name="nome"
            />
          </Grid>
        </Grid>

        <Divider />

        {isUpdate && <SalarioItens salarioId={data.id} />}
      </Stack>
    </ActionDialog>
  )
}
