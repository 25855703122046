import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterTodosSalarioInvalidate } from './useObterTodosSalario'

import { notifyPut } from '~/utils/notification'

import { SalarioDTO } from './dtos/SalarioDTO'

interface RequestParams {
  data: SalarioDTO
  id: string
}

export function useUpdateSalario() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosSalarioInvalidate()

  async function handleRequest({ data, id }: RequestParams) {
    await api.put('/Salario/' + id, data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
