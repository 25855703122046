import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { IndCompetenciaProcessEnum } from '~/@types/enums/IndCompetenciaProcessEnum'

interface RequestProps {
  params: {
    anoMes: string
    indCompetenciaProcess: IndCompetenciaProcessEnum
  }
  data: string[]
}

export default function useProcessarCompetencia() {
  const dialogNotificaiton = useDialogNotification()
  const notification = useNotification()

  async function handleRequest({ data, params }: RequestProps) {
    await api.post('/Competencia/ProcessarCompetencia', data, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Processamento Iniciado com Sucesso'),
    onError: dialogNotificaiton.extractErrors,
  })
}
