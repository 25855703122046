import { Box, Divider, LinearProgress, Typography, useTheme } from '@material-ui/core'

import AlternateButton from '../AlternateButton'
import IconPaper from '../IconPaper'

interface ChartHeaderProps {
  title: string
  subtitle?: string
  icon: string
  onClickRedirect?: () => void
  onClickAlternate: () => void
  isActiveAlternate: boolean
  isHover: boolean
  isLoading: boolean
  divider?: boolean
}

export default function ChartHeader({
  title,
  subtitle,
  icon,
  onClickRedirect,
  onClickAlternate,
  isActiveAlternate,
  isHover,
  isLoading,
  divider = true,
}: ChartHeaderProps) {
  const theme = useTheme()

  return (
    <Box position="relative">
      <AlternateButton isActive={isActiveAlternate} show={isHover} onClick={onClickAlternate} />

      <Box display="flex" alignItems="center" mb={1} gridGap={theme.spacing(2)}>
        <IconPaper icon={icon} onClick={onClickRedirect} />
        <Box>
          <Box component={Typography} fontWeight="bolder" fontSize="12pt" color="#666">
            {title}
          </Box>
          <Typography
            variant="h4"
            style={{
              fontWeight: 'bold',
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
      {divider && <Divider />}
      <Box height={4}>{isLoading && <LinearProgress />}</Box>
    </Box>
  )
}
