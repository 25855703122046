import { RefObject, useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { CurrencyTextField, DatePickerNew, TextField } from '~/components'
import { AutoCompleteSalario, MUIAutoComplete } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import {
  IndSalarioContratualEnum,
  IndSalarioContratualValues,
} from '~/@types/enums/IndSalarioContratualEnum'

import { VinculoSalarioBase } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'
import { FaixaSalarialValues } from '~/@types/enums/FaixaSalarialEnum'

const QUANTIDADE_SALARIOS_ALERTAR = 10

const schema = yup.object().shape({
  dtSalario: yup
    .date()
    .required('Informe Data Salário')
    .typeError('Informe uma data válida')
    .nullable(),
  dtEfetiva: yup
    .date()
    .required('Informe Data Efetiva')
    .typeError('Informe uma data válida')
    .nullable(),
  indSalario: yup
    .mixed()
    .oneOf(Object.values(IndSalarioContratualEnum), 'Informe o Indicador de Salário'),
  quantidade: yup.number().when(['indSalario'], (indSalario: IndSalarioContratualEnum, schema) => {
    if (indSalario !== IndSalarioContratualEnum.Valor)
      return schema.required('Informe a Quantidade').min(1, 'Informe a Quantidade de Salários')
  }),
  vrSalario: yup.number().when(['indSalario'], (indSalario: IndSalarioContratualEnum, schema) => {
    if (indSalario === IndSalarioContratualEnum.Valor)
      return schema.required('Informe o Valor').min(0.0001, 'Informe o Valor')
  }),
  faixaSalarial: yup
    .string()
    .when(['indSalario'], (indSalario: IndSalarioContratualEnum, schema) => {
      if (
        indSalario === IndSalarioContratualEnum.CategoriaSindical ||
        indSalario === IndSalarioContratualEnum.TabelaSalario
      )
        return schema.required('Informe a Faixa Salarial')
    }),
  salarioId: yup.string().when(['indSalario'], (indSalario: IndSalarioContratualEnum, schema) => {
    if (indSalario === IndSalarioContratualEnum.TabelaSalario)
      return schema.required('Informe o Salário')
  }),
})

interface SalarioContratualFormProps {
  data: VinculoSalarioBase
  disableFields?: (keyof VinculoSalarioBase)[]
  onSubmit: (data: VinculoSalarioBase) => void
  buttonRef: RefObject<HTMLButtonElement>
}

export default function SalarioContratualForm({
  data: _data,
  disableFields = [],
  onSubmit,
  buttonRef,
}: SalarioContratualFormProps) {
  const [data, setData] = useState(_data)

  const dialogNotification = useDialogNotification()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    if ((data?.quantidade || 0) > QUANTIDADE_SALARIOS_ALERTAR) {
      return dialogNotification.warning({
        descriptions: [
          `A quantidade de salários ultrapassa ${QUANTIDADE_SALARIOS_ALERTAR}, essa informação está correta?`,
        ],
        labelOnConfirm: 'Sim',
        labelOnCancel: 'Não',
        onConfirm: () => onSubmit(data),
      } as FixLater)
    }
    onSubmit(data)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          label="Data Salário"
          size="small"
          value={data.dtSalario || null}
          required
          validationErrors={validationErrors}
          name="dtSalario"
          disabled={disableFields.includes('dtSalario')}
          onChange={(date) => {
            const dtSalario = date || ''
            setData((prev) => ({ ...prev, dtSalario }))
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          label="Data Efetiva"
          size="small"
          value={data.dtEfetiva || null}
          required
          validationErrors={validationErrors}
          name="dtEfetiva"
          disabled={disableFields.includes('dtEfetiva')}
          onChange={(date) => {
            const dtEfetiva = date || ''
            setData((prev) => ({ ...prev, dtEfetiva }))
          }}
        />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
        <MUIAutoComplete
          label="Indicador de Salário"
          options={IndSalarioContratualValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="indSalario"
          value={data.indSalario}
          disabled={data?.id ? true : false}
          onChange={(e, obj) => {
            const indSalario = obj ? obj.value : ''
            setData((prev) => ({
              ...prev,
              indSalario,
              quantidade: 0,
              vrSalario: 0,
              faixaSalarial: undefined,
              salarioId: '',
            }))
          }}
        />
      </Grid>

      {data?.indSalario !== IndSalarioContratualEnum.Valor && (
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Quantidade"
            fullWidth
            value={data.quantidade || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="quantidade"
            onChange={(e, value) => {
              const quantidade = value || 0
              setData({ ...data, quantidade })
            }}
          />
        </Grid>
      )}

      {data?.indSalario === IndSalarioContratualEnum.Valor && (
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Valor"
            fullWidth
            value={data?.vrSalario || ''}
            variant="outlined"
            size="small"
            onChange={(_, obj) => {
              const vrSalario = obj || 0
              setData((oldState) => ({
                ...oldState,
                vrSalario,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrSalario"
          />
        </Grid>
      )}

      {(data?.indSalario === IndSalarioContratualEnum.TabelaSalario ||
        data?.indSalario === IndSalarioContratualEnum.CategoriaSindical) && (
        <>
          {data?.indSalario === IndSalarioContratualEnum.TabelaSalario && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AutoCompleteSalario
                name="salarioId"
                required
                validationErrors={validationErrors}
                value={data?.salarioId || ''}
                onChange={(d) => {
                  const salarioId = d?.id || ''
                  setData((prev) => ({ ...prev, salarioId }))
                }}
              />
            </Grid>
          )}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MUIAutoComplete
              label="Faixa Salarial"
              options={FaixaSalarialValues}
              renderOption={(opt) => opt?.name || ''}
              optionId="value"
              value={data?.faixaSalarial}
              onChange={(e, obj) =>
                setData((prevState) => ({
                  ...prevState,
                  faixaSalarial: obj?.value || '',
                }))
              }
              required
              validationErrors={validationErrors}
              name="faixaSalarial"
            />
          </Grid>
        </>
      )}

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Observação"
          fullWidth
          value={data.observacao || ''}
          size="small"
          multiline
          inputProps={{
            maxLength: 200,
          }}
          onChange={(e) => {
            const observacao = e.target.value
            setData({ ...data, observacao })
          }}
          disabled={data?.id ? true : false}
        />

        <button ref={buttonRef} onClick={handleValidate} />
      </Grid>
    </Grid>
  )
}
