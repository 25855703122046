import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Grid } from '@material-ui/core'

import { ActionDrawer, MultipleSelectCheck } from '~/components'
import { AutoCompleteGrupo, MUIAutoComplete } from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'

import { IndApuracaoEnum, IndApuracaoValues } from '~/@types/enums/IndApuracaoEnum'
import { ESocialEventoEnum, ESocialEventoValues } from '~/@types/enums/ESocialEventoEnum'
import { DataContextProps } from '..'
import { ParametrosSistema } from '~/hooks/queries/ParametrosSistema/ParametrosSistema'
import { TipoLicencaEnum } from '~/@types/enums/TipoLicencaEnum'

interface FilterProps {
  value: ESocialEventoEnum
  name: string
}

interface DrawerFilterProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (newData: DataContextProps) => void
  data: DataContextProps
}

const eventosSSTeSocial = [
  ESocialEventoEnum.S_2210,
  ESocialEventoEnum.S_2220,
  ESocialEventoEnum.S_2240,
]

export default function DrawerFilter(props: DrawerFilterProps) {
  const { isOpen, onClose, onSubmit, data } = props

  const [indApuracao, setIndApuracao] = useState<IndApuracaoEnum>(IndApuracaoEnum.Mensal_1)
  const [filter, setFilter] = useState<FilterProps[]>([])
  const [grupoId, setGrupoId] = useState<string | null>(null)

  const { mes } = useAmbiente()
  const parametrosSistema = useSelector(
    (state: { app: { parametrosSistema: ParametrosSistema } }) => state.app.parametrosSistema,
  )

  const isLicencaSST = parametrosSistema.tipoLicenca === TipoLicencaEnum.SST_2

  const hasFilterApuracao = mes === '12'

  const eSocialEventoValuesCurrent = isLicencaSST
    ? ESocialEventoValues.filter((d) => eventosSSTeSocial.includes(d.value))
    : ESocialEventoValues

  useEffect(() => {
    setIndApuracao(data.indApuracao)
    setFilter(
      data.eventosFilter.map((d) => {
        const eventoFilterValue = eSocialEventoValuesCurrent.find(
          (eventoValue) => eventoValue.value === d,
        )
        return {
          name: eventoFilterValue?.name || '',
          value: d,
        }
      }),
    )
    setGrupoId(data.grupoId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function handleSubmit() {
    onSubmit({
      ...data,
      indApuracao,
      eventosFilter: filter.map((d) => d.value),
      grupoId,
    })
    onClose()
  }

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={onClose}
      title="Transmissão eSocial - Filtros"
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MultipleSelectCheck
            options={eSocialEventoValuesCurrent}
            getOptionLabel={(option) => option.name}
            label="Filtrar por Evento"
            fieldCompare="name"
            value={filter}
            onChange={setFilter}
          />
        </Grid>

        {isLicencaSST ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <AutoCompleteGrupo
              value={grupoId}
              onChange={(e, grupo) => {
                setGrupoId(grupo?.id || null)
              }}
            />
          </Grid>
        )}

        {hasFilterApuracao && (
          <Grid item xs={12}>
            <MUIAutoComplete
              label="Indicador de Apuração"
              options={IndApuracaoValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={indApuracao}
              onChange={(e, obj) => {
                const indApuracao = obj ? obj.value : IndApuracaoEnum.Mensal_1
                setIndApuracao(indApuracao)
              }}
            />
          </Grid>
        )}
      </Grid>
    </ActionDrawer>
  )
}
