import { useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import ContainerChart from '../components/ContainerChart'
import ChartHeader from '../components/ChartHeader'
import Chart from '../components/PieChart'
import TableChart from '../components/TableChart'
import IndicatorStatus from '../components/IndicatorStatus'

import useAmbiente from '~/hooks/useAmbiente'
import { useHistory } from 'react-router-dom'
import usePainelObterVencimentosChart from '~/hooks/queries/Painel/usePainelObterVencimentosChart'

import {
  IndConsultaVencimentosColor,
  IndConsultaVencimentosLabel,
} from '~/@types/enums/IndConsultaVencimentosEnum'
import { MAX_HEIGHT_CHART, MAX_WIDTH_CHART } from '..'

export default function VencimentosChart() {
  const { anoMes, estabelecimento } = useAmbiente()
  const history = useHistory()

  const [isHover, setHover] = useState(false)
  const [estabelecimentoId, setEstabelecimentoId] = useState('')

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching,
  } = usePainelObterVencimentosChart({
    anoMes,
    estabelecimentoId,
  })
  const data = _data || []
  const isLoading = _isLoading || isFetching

  function handleRedirect() {
    history.push('/consulta-vencimentos')
  }

  const hasSomeData = data.some((d) => d.total !== 0)

  return (
    <ContainerChart onHover={setHover}>
      <ChartHeader
        title="Consulta de Vencimentos"
        icon="search"
        isActiveAlternate={estabelecimentoId ? false : true}
        onClickRedirect={handleRedirect}
        onClickAlternate={() => setEstabelecimentoId((prev) => (prev ? '' : estabelecimento.id))}
        isHover={isHover}
        isLoading={isLoading}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              maxWidth={MAX_WIDTH_CHART}
              maxHeight={MAX_HEIGHT_CHART}
            >
              {hasSomeData ? (
                <Chart
                  data={data}
                  labels={IndConsultaVencimentosLabel}
                  colors={IndConsultaVencimentosColor}
                  onClick={handleRedirect}
                  cutout="75%"
                  displayLegend={false}
                />
              ) : (
                <Typography>Sem informações</Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <TableChart
            columns={[
              {
                name: 'status',
                label: 'Vencimento',
                options: {
                  customBodyRender: (index) => IndConsultaVencimentosLabel[data[index].status],
                },
              },
              {
                name: 'total',
                label: 'Total',
              },
              {
                name: 'status',
                label: 'Indicador',
                options: {
                  customBodyRender: (index) => (
                    <IndicatorStatus color={IndConsultaVencimentosColor[data[index].status]} />
                  ),
                },
              },
            ]}
            onClickRow={() => handleRedirect()}
            data={data}
          />
        </Grid>
      </Grid>
    </ContainerChart>
  )
}
