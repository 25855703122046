export enum FPMovimentoTipoEnum {
  ReciboMensal = 0,
  Ferias = 1,
  Rescisao = 2,
  GRFC = 3,
  ProLabore = 4,
  RPA = 5,
  DecimoTerceiroPrimeiraParcela = 6,
  DecimoTerceiroSegundaParcela = 7,
  ParametrosGerais = 999,
}
