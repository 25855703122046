import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { Ativo } from '~/components/Displays'
import { ButtonBox, ToolsDataTable } from '~/components'

import { useObterTodos } from '~/hooks/queries/ModeloIntegracao/useObterTodos'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { LayoutEnum, LayoutLabel } from '~/@types/enums/LayoutEnum'
import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'

interface TableProps {
  onItemClick: (event: 'delete' | 'edit', data: ModeloIntegracao) => void
  query: string
}

export default function Table({ onItemClick, query }: TableProps) {
  const { data: _d, isLoading, isFetching } = useObterTodos()
  const data = _d || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'mascaraConta',
      label: 'Máscara',
    },
    {
      name: 'layoutTipo',
      label: 'Layout',
      options: {
        customBodyRender: (value: LayoutEnum) => LayoutLabel[value],
      },
    },
    {
      name: 'ativo',
      label: 'Ativo',
      options: {
        customBodyRender: (value) => <Ativo ativo={value} />,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => (
          <ButtonBox spacing={0} justifyContent="center">
            <IconButton
              size="small"
              title="Editar este registro"
              color="primary"
              aria-label="Editar"
              onClick={() => {
                const dt = data.find((d) => d.id === value)
                if (dt) {
                  onItemClick('edit', dt)
                }
              }}
            >
              <EditIcon fontSize="small" color="primary" />
            </IconButton>

            <IconButton
              size="small"
              title="Excluir este registro"
              color="primary"
              aria-label="Excluir"
              onClick={() => {
                const dt = data.find((d) => d.id === value)
                if (dt) {
                  onItemClick('delete', dt)
                }
              }}
            >
              <DeleteIcon fontSize="small" color="primary" />
            </IconButton>
          </ButtonBox>
        ),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'mascaraConta'],
      }}
    />
  )
}
