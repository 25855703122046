import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { openDownloadData } from '~/hooks/useUtils'
import { notifySuccess } from '~/utils/notification'

import { LancamentoContabilFiltroDTO } from './dtos/LancamentoContabilFiltroDTO'
import { AxiosError } from 'axios'

interface RequestProps {
  data: LancamentoContabilFiltroDTO
}

export default function useGerarArquivo() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data }: RequestProps) {
    const response = await api.post<Blob>('/LancamentoContabil/GerarArquivo', data, {
      responseType: 'arraybuffer',
    })

    const contentDisposition = response.headers['content-disposition']
    let filename = 'downloaded_file.txt'

    if (contentDisposition) {
      const utf8FilenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/)
      const asciiFilenameMatch = contentDisposition.match(/filename="(.+?)"/)

      if (utf8FilenameMatch) {
        filename = decodeURIComponent(utf8FilenameMatch[1])
      } else if (asciiFilenameMatch) {
        filename = asciiFilenameMatch[1]
      }
    }

    return { file: response.data, filename }
  }

  return useMutation<
    { file: Blob; filename: string },
    AxiosError<{ errors: string[] }>,
    RequestProps
  >((d) => handleRequest(d), {
    onSuccess: ({ filename, file }) => {
      openDownloadData(filename, file)
      notifySuccess('Arquivo gerado com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
