import { useState } from 'react'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  Finder,
  PageHeader,
  ToolsDataTable,
  ContainerTable,
  StackContainer,
} from '~/components'

import Form from './Form'

import useDialog from '~/hooks/useDialog'

import { useObterTodosSalario } from '~/hooks/queries/Salario/useObterTodosSalario'
import { useRemoveSalario } from '~/hooks/queries/Salario/useRemoveSalario'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { Salario } from '~/hooks/queries/Salario/Salario'

export default function SalarioPage() {
  const [query, setQuery] = useState('')

  const { data: _data, isLoading, isFetching } = useObterTodosSalario()
  const data = _data || []

  const { mutateAsync: mutateAsyncDelete, isLoading: isDeleting } = useRemoveSalario()

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<Salario | null>(null)

  async function handleConfirmDelete() {
    await mutateAsyncDelete(dataConfirmDelete)
    closeConfirmDelete()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtCurrent = data.find((d) => d.id === value)
                  if (!dtCurrent) return
                  openForm(dtCurrent)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => openConfirmDelete(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <StackContainer>
      <PageHeader title="Salários">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button variant="contained" onClick={() => openForm(null)}>
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <ContainerTable>
        <ToolsDataTable
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          columns={columns}
          sherlock={{
            query,
            columns: ['nome', 'codigo'],
          }}
          disableAutoHeight
        />
      </ContainerTable>

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isDeleting}
          onCancel={closeConfirmDelete}
          onConfirm={handleConfirmDelete}
        />
      )}

      {isOpenForm && <Form isOpen={isOpenForm} onClose={closeForm} data={dataForm} />}
    </StackContainer>
  )
}
