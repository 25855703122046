import { useEffect, useState } from 'react'

import { Badge, Box, Paper, Typography } from '@material-ui/core'
import { Description, Error } from '@material-ui/icons'

import { Button, ContainerTable, PageHeader, Stack, StackContainer } from '~/components'
import AutoCompleteModeloIntegracao from '~/components/AutoComplete/AutoCompleteModeloIntegracao'

import useAmbiente from '~/hooks/useAmbiente'

import useGerarArquivo from '~/hooks/queries/LancamentoContabil/useGerarArquivo'
import { useInvalidate } from '~/hooks/queries/ExportacaoLancamentoConfig/useResumoConfiguracoesLotacoes'

import Table from './components/Table'
import DialogConfigLotacao from './components/DialogConfigLotacao'
import DialogErrors from './components/DialogErrors'

import { getDateMinAnoMes, getDateMaxAnoMes } from '~/utils/utils'

import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'
import useDialog from '~/hooks/useDialog'
import { LotacaoConfigConsultaDTO } from '~/hooks/queries/ExportacaoLancamentoConfig/dtos/LotacaoConfigConsultaDTO'

export default function GerarArquivoImportacao() {
  const [form, setForm] = useState<{
    modeloIntegracao: ModeloIntegracao | null
  }>({
    modeloIntegracao: null,
  })
  const [errors, setErrors] = useState<string[]>([])

  const {
    close: closeDialogConfigLotacao,
    data: dataDialogConfigLotacao,
    isOpen: isOpenDialogConfigLotacao,
    open: openDialogConfigLotacao,
  } = useDialog<LotacaoConfigConsultaDTO>({} as LotacaoConfigConsultaDTO)

  const {
    close: closeDialogErrors,
    data: dataDialogErrors,
    isOpen: isOpenDialogErrors,
    open: openDialogErrors,
  } = useDialog<string[]>([])

  const { anoMes, estabelecimento } = useAmbiente()

  const { mutateAsync, isLoading: isSubmitting, error } = useGerarArquivo()
  const { handleInvalidate } = useInvalidate()

  useEffect(() => {
    const _erros = error?.response?.data?.errors || []
    const errors: string[] = []
    _erros.forEach((err) => errors.push(...err.split(';')))
    setErrors(errors)
  }, [error])

  useEffect(() => {
    setErrors([])
  }, [form.modeloIntegracao, estabelecimento])

  async function handleGerarArquivo() {
    const dtInicio = getDateMinAnoMes(anoMes)
    const dtFim = getDateMaxAnoMes(anoMes)

    try {
      await mutateAsync({
        data: {
          nrInscricao: estabelecimento.nrInscricao,
          modeloIntegracaoId: form?.modeloIntegracao?.id as string,
          dtInicio,
          dtFim,
        },
      })
    } catch (error) {
      console.error(error)
    }

    handleInvalidate()
  }

  function handleClickItem(dt: LotacaoConfigConsultaDTO) {
    openDialogConfigLotacao(dt)
  }

  return (
    <Box height="100%" position={isOpenDialogConfigLotacao ? 'static' : 'relative'}>
      <StackContainer component={Paper}>
        <PageHeader title="Gerar Arquivo de Importação" />

        <Stack orientation="horizontal" alignItems="center">
          <AutoCompleteModeloIntegracao
            value={form?.modeloIntegracao?.id || ''}
            onChange={(d) => {
              const modeloIntegracao = d || null
              setForm((prev) => ({ ...prev, modeloIntegracao }))
            }}
          />
          <Box width={250} display="flex" justifyContent="center">
            <Badge badgeContent={errors.length} color="error">
              <Button
                variant="contained"
                endIcon={<Error />}
                onClick={() => openDialogErrors(errors)}
              >
                Erros / Inconsistências
              </Button>
            </Badge>
          </Box>
        </Stack>

        {!form?.modeloIntegracao ? (
          <Typography>Selecione o modelo de integração para prosseguir</Typography>
        ) : (
          <>
            <ContainerTable>
              <Table modeloIntegracaoId={form.modeloIntegracao.id} onClickItem={handleClickItem} />
            </ContainerTable>

            <Stack orientation="horizontal" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleGerarArquivo}
                isLoading={isSubmitting}
                endIcon={<Description />}
              >
                Gerar arquivo
              </Button>
            </Stack>
          </>
        )}
      </StackContainer>

      {isOpenDialogConfigLotacao && (
        <DialogConfigLotacao
          lotacaoConfig={dataDialogConfigLotacao}
          onClose={closeDialogConfigLotacao}
          modeloIntegracao={form.modeloIntegracao as ModeloIntegracao}
        />
      )}

      <DialogErrors
        onOpen={() => openDialogErrors(errors)}
        onClose={closeDialogErrors}
        isOpen={isOpenDialogErrors}
        data={dataDialogErrors}
      />
    </Box>
  )
}
